import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Icons = ({ iconData, projectName }) => {
    const { t } = useTranslation();

    let icons = iconData.reduce((total, current, index) => {
        if (index === 0 || index % 3 === 0) {
            total.push([]);
        }
        total[total.length - 1].push(
            <img src={current} alt={`${current}`} key={`icons-${index}`} />
        );
        return total;
    }, []);

    return (
        <React.Fragment>
            <div className="project-data-custom-title">
                {t(`projects-page.${projectName}.design.content.icons`)}
            </div>
            <div className="project-icons">
                {icons.map((currentArr, index) => (
                    <div
                        className="project-icons-wrapper"
                        key={`icons-wrapper-${index}`}
                    >
                        {currentArr}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Icons;

Icons.propTypes = {
    iconData: PropTypes.arrayOf(PropTypes.string).isRequired,
    projectName: PropTypes.string.isRequired,
};
