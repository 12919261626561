import React from "react";
import PropTypes from "prop-types";

const ProjectPart = ({ title, description }) => (
	<React.Fragment>
		<div className={`project-data-common-title`}>
			{title}
		</div>
		<div className={`project-data-common-description`}>
			{description}
		</div>
	</React.Fragment>
);

export default ProjectPart;

ProjectPart.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};