import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Colors = ({ colorData, projectName }) => {
    const { t } = useTranslation();

    let colors = colorData.reduce((total, current, index) => {
        if (index === 0 || index % 3 === 0) {
            total.push([]);
        }
        total[total.length - 1].push(
            <div style={{ backgroundColor: current }} key={`color-${index}`}>
                {null}
            </div>
        );
        return total;
    }, []);

    return (
        <React.Fragment>
            <div className="project-data-custom-title">
                {t(`projects-page.${projectName}.design.content.colors`)}
            </div>
            <div className="project-colors">
                {colors.map((currentArr, index) => (
                    <div
                        className="project-colors-wrapper"
                        key={`color-wrapper-${index}`}
                    >
                        {currentArr}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Colors;

Colors.propTypes = {
    colorData: PropTypes.arrayOf(PropTypes.string).isRequired,
    projectName: PropTypes.string.isRequired,
};
