import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import PropTypes from "prop-types";

const Download = ({googleLink, appleLink}) => {
    const data = useStaticQuery(graphql`
        query {
            googleIcons: file(relativePath: {eq: "projects/download/1-google-play-badge.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            appleIcons: file(relativePath: {eq: "projects/download/2-app-store-badge.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    const DownloadLink = ({url, img}) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
            <GatsbyImage alt="" image={img} />
        </a>
    );

    return (
        <React.Fragment>
            <div className="project-download">
                {!!googleLink ? (
                    <DownloadLink url={googleLink} img={data.googleIcons.childImageSharp.gatsbyImageData} />
                ) : null}
                {!!appleLink ? (
                    <DownloadLink url={appleLink} img={data.appleIcons.childImageSharp.gatsbyImageData} />
                ) : null}
            </div>
        </React.Fragment>
    );
};

export default Download;

Download.propTypes = {
    googleLink: PropTypes.string,
    appleLink: PropTypes.string,
};
