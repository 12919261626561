import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Fonts = ({ fontName, projectName }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="project-data-custom-title">
                {t(`projects-page.${projectName}.design.content.icons`)}
            </div>
            <div className="project-font-family">
                <div className="project-font-family-title">{fontName}</div>
                <div className="project-font-family-caps">
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </div>
                <div className="project-font-family-normal">
                    abcdefghijklmnopqrstuvwxyz
                </div>
            </div>
        </React.Fragment>
    );
};

export default Fonts;

Fonts.propTypes = {
    fontName: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
};
