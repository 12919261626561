import React from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {useTranslation} from "react-i18next";
import ProjectPart from "./common/projectPart";
import Icons from "./common/icons";
import Colors from "./common/colors";
import Fonts from "./common/fonts";
import PropTypes from "prop-types";
import Download from "./common/download";

const ProjectComponent = ({projectData}) => {
    const {t} = useTranslation();

    let counter = 1;

    return (
        <div className="projects-main">
            <div className="container">
                <div className="projects-main-title">{t(`projects-page.${projectData.project}.title`)}</div>
            </div>
            <div className="projects-main-banner">
                <GatsbyImage alt="" image={projectData.banner} />
            </div>
            <div className="container">
                <div className="projects-main-parts">
                    {projectData.stages.about ? (
                        <div className="project-about">
                            <div className={`project-index`}>{projectData.stages.about ? "0" + counter++ : null}</div>
                            <div className="project-data">
                                <div className="project-data-common">
                                    <ProjectPart
                                        title={t(`projects-page.${projectData.project}.about.title`)}
                                        description={t(`projects-page.${projectData.project}.about.description`)}
                                    />
                                </div>
                                <div className="project-data-custom">
                                    <div className="project-data-custom-title">
                                        {t(`projects-page.${projectData.project}.about.content.info.title`)}
                                    </div>
                                    <div className="project-data-custom-description">
                                        {t(`projects-page.${projectData.project}.about.content.info.description`)}
                                    </div>

                                    <div className="project-data-custom-title">
                                        {t(`projects-page.${projectData.project}.about.content.client.title`)}
                                    </div>
                                    <div className="project-data-custom-description">
                                        {t(`projects-page.${projectData.project}.about.content.client.description`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {projectData.stages.design ? (
                        <div className="project-02">
                            <div className={`project-index`}>{projectData.stages.design ? "0" + counter++ : null}</div>
                            <div className="project-data">
                                <div className="project-data-common">
                                    <ProjectPart
                                        title={t(`projects-page.${projectData.project}.design.title`)}
                                        description={t(`projects-page.${projectData.project}.design.description`)}
                                    />
                                </div>
                                <div className="project-data-custom">
                                    {!!projectData.colors.length ? (
                                        <Colors colorData={projectData.colors} projectName={projectData.project} />
                                    ) : null}
                                    {!!projectData.font ? (
                                        <Fonts fontName={projectData.font} projectName={projectData.project} />
                                    ) : null}
                                    {!!projectData.icons.length ? (
                                        <Icons iconData={projectData.icons} projectName={projectData.project} />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {projectData.stages.tech ? (
                        <div className="project-03">
                            <div className={`project-index`}>{projectData.stages.tech ? "0" + counter++ : null}</div>
                            <div className="project-data">
                                <div className="project-data-common">
                                    <ProjectPart
                                        title={t(`projects-page.${projectData.project}.technologies.title`)}
                                        description={t(`projects-page.${projectData.project}.technologies.description`)}
                                    />
                                </div>
                                <div className="project-data-custom">
                                    <div className="project-data-custom-description">
                                        {t(`projects-page.${projectData.project}.technologies.content.0`)}
                                    </div>
                                    <div className="project-data-custom-description">
                                        {t(`projects-page.${projectData.project}.technologies.content.1`)}
                                    </div>
                                    <div className="project-data-custom-description">
                                        {t(`projects-page.${projectData.project}.technologies.content.2`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {projectData.stages.result ? (
                        <div className="project-04">
                            <div className="project-data">
                                <div>
                                    <div className={`project-index`}>
                                        {projectData.stages.result ? "0" + counter++ : null}
                                    </div>
                                    <div className="project-data-common">
                                        <ProjectPart
                                            title={t(`projects-page.${projectData.project}.result.title`)}
                                            description={t(`projects-page.${projectData.project}.result.description`)}
                                        />
                                    </div>
                                </div>
                                <div className="project-data-custom">
                                    <GatsbyImage alt="" image={projectData.picture} />
                                </div>
                            </div>
                            {!!projectData.googlePlay || !!projectData.appStore ? (
                                <Download googleLink={projectData.googlePlay} appleLink={projectData.appStore} />
                            ) : null}
                            {!!projectData.website ? (
                                <a
                                    className={"project-link"}
                                    href={projectData.website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t(`projects-page.website-button`)}
                                </a>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="projects-main-footer">
                <p className="projects-main-footer-text">{t(`projects-page.footer.text.top`)}</p>
                <p className="projects-main-footer-text">{t(`projects-page.footer.text.bottom`)}</p>
                <Link to="/#contact" className="projects-main-footer-btn">
                    {t(`projects-page.footer.btn`)}
                </Link>
            </div>
        </div>
    );
};

export default ProjectComponent;

ProjectComponent.propTypes = {
    projectData: PropTypes.shape({
        website: PropTypes.string,
        project: PropTypes.string.isRequired,
        banner: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
        }),
        stages: PropTypes.shape({
            about: PropTypes.bool.isRequired,
            design: PropTypes.bool.isRequired,
            tech: PropTypes.bool.isRequired,
            result: PropTypes.bool.isRequired,
        }).isRequired,
        colors: PropTypes.arrayOf(PropTypes.string),
        font: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.string),
        picture: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
        }),
        googlePlay: PropTypes.string,
        appStore: PropTypes.string,
    }).isRequired,
};
